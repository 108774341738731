import {useSlideOverStore} from "@/store/slideOver";

const slideOverStore = useSlideOverStore()

type HeaderMenuItem = {
    title: string;
    path?: string | Object;
    function?: () => void;
    icon?: string;
    color?: string;
    initial?: string;
    ability?: string[];
    children?: HeaderMenuItem[];
    showUnauthenticated: boolean;
    showAuthenticated: boolean;
}

type HeaderMenu = HeaderMenuItem[];

export const headerMenu: HeaderMenu = [
    {
        title: 'menu.home',
        path: '/',
        icon: 'heroicons:home-solid',
        showUnauthenticated: true,
        showAuthenticated: true,
    },
    ...(useRuntimeConfig()?.public?.underConstruction ? [] :
            [
                {
                    title: 'menu.fundingPrograms',
                    function: () => {
                        slideOverStore.setSlideOverOpen(true);
                        slideOverStore.setTitle('menu.fundingPrograms');
                        slideOverStore.setDescription('fundingPrograms.slideOver.description');
                        slideOverStore.setComponent('FundingProgramsSelection');
                        slideOverStore.setContext(null);
                    },
                    showUnauthenticated: true,
                    showAuthenticated: true,
                },
                {
                    title: 'menu.processes',
                    path: 'processes',
                    ability: ['viewAny', 'processes'],
                    showUnauthenticated: false,
                    showAuthenticated: true,
                },
                {
                    title: 'menu.statistics',
                    path: 'statistics',
                    ability: ['viewAny', 'statistics'],
                    showUnauthenticated: false,
                    showAuthenticated: true,

                }
            ]
    ),
    {
        title: 'menu.login',
        function: () => {
            slideOverStore.setSlideOverOpen(true);
            slideOverStore.setTitle('menu.login');
            slideOverStore.setDescription('');
            slideOverStore.setComponent('Login');
            slideOverStore.setContext(null);
        },
        showUnauthenticated: true,
        showAuthenticated: false,
    }
];